import { HEADER_PAGES, HEADER_PAGE_PARAM } from 'constants/headerNavMenu';
import { HeaderPageType } from '../../interface';

/**
 *  TOPページ・検索結果一覧ページ・お気に入りリストページからの遷移時に、パラメータを付与する
 * @param {string} strLoginStatus ログインステータス
 * @param {string} currentPage 現在のページ
 * @param {string} baseUrl ベースURL
 * @param {string} pageParam 画面パラメータ
 * @returns baseUrl + パラメータ
 */
export const createNavItemUrl = (
  strLoginStatus: string,
  currentPage: HeaderPageType['currentPage'],
  baseUrl: string,
  pageParam: string
) => {
  if (currentPage === HEADER_PAGES.OTHER || !pageParam) return baseUrl;

  if (currentPage === HEADER_PAGES.TOP) {
    return `${baseUrl}?${HEADER_PAGE_PARAM.TOP.USRCLK}=PC_${strLoginStatus}${HEADER_PAGE_PARAM.TOP.originParam}${pageParam}`;
  }

  if (currentPage === HEADER_PAGES.JOB_SEARCH_LIST) {
    return `${baseUrl}?${HEADER_PAGE_PARAM.JOB_SEARCH_LIST.USRCLK}=PC-${strLoginStatus}${HEADER_PAGE_PARAM.JOB_SEARCH_LIST.originParam}${pageParam}`;
  }
  // TODO: お気に入りリストページ追加時にパラメータが新基盤と同じか確認
  if (currentPage === HEADER_PAGES.BOOKMARK_LIST) {
    return `${baseUrl}?${HEADER_PAGE_PARAM.BOOKMARK_LIST.USRCLK}=PC-${strLoginStatus}${HEADER_PAGE_PARAM.BOOKMARK_LIST.originParam}${pageParam}`;
  }

  return baseUrl;
};
