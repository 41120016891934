import { FC } from 'react';
import {
  BrowsingHistory,
  Heart,
  Login,
  Logout,
  Register,
  Resume,
  Setting,
} from '@doda/io-react/common';
import { NotificationInfoItem as NotificationInfoItemType } from '@doda/common/src/interfaces/api/notificationInfo';
import { measureOnClickHeader } from 'components/Header/logics/analytics';
import { MEASURE_ONCLICK_HEADER } from 'components/Header/constants';
import { useAppDispatch } from '@doda/common/src/redux/hooks';
import { HEADER_PAGES } from 'constants/headerNavMenu';
import { HeaderPageType } from 'components/Header/interface';
import { getIconMeneUrl } from 'components/Header/logics/getIconMeneUrl';
import { usePopoverVisible } from '../../hooks/usePopover';
import { HeaderResumePopover } from '../HeaderResumePopover';
import { HeaderNotificationPopover } from '../HeaderNotificationPopover';
import { LabeledIcon } from '../LabeledIcon';
import styles from './index.module.scss';
import { useLogin } from './hooks/useLogin';
import { NotificationIcon } from '../NotificationIcon';

/** LabeledIconMenuコンポーネントのプロパティ */
interface Props {
  /** ユーザーがログインしているかどうかを示すフラグ */
  isLoggedIn: boolean;
  /** 閲覧履歴の件数 */
  browsingHistoryCount: number;
  /** 気になるリストの件数 */
  bookmarkCount: number;
  /** 通知のリスト */
  notifications: NotificationInfoItemType[];
  /** 通知バッジの件数 */
  badgeCount: number;
  /** 履歴書ポップアップを表示するかどうかを示すフラグ */
  shouldShowResumePopup: boolean;
  /** Adobe Analyticsスクリプトがあるかどうかを示すフラグ */
  hasAdobeAnalyticsScripts: boolean;
  /** 現在のページ情報 */
  currentPage: HeaderPageType['currentPage'];
}

/**
 * LabeledIconMenuコンポーネント
 *
 * @param {Props} props - コンポーネントのプロパティ
 * @param {boolean} props.isLoggedIn - ユーザーがログインしているかどうかを示すフラグ
 * @param {number} props.browsingHistoryCount - 閲覧履歴の件数
 * @param {number} props.bookmarkCount - 気になるリストの件数
 * @param {NotificationInfoItemType[]} props.notifications - 通知のリスト
 * @param {number} props.badgeCount - 通知バッジの件数
 * @param {boolean} props.shouldShowResumePopup - 履歴書ポップアップを表示するかどうかを示すフラグ
 * @param {boolean} props.hasAdobeAnalyticsScripts - Adobe Analyticsスクリプトがあるかどうかを示すフラグ
 * @param {string} props.currentPage - 現在のページ情報
 * @returns {JSX.Element} - LabeledIconMenuコンポーネント
 */
export const LabeledIconMenu: FC<Props> = ({
  isLoggedIn,
  browsingHistoryCount,
  bookmarkCount,
  notifications,
  badgeCount,
  shouldShowResumePopup,
  hasAdobeAnalyticsScripts,
  currentPage,
}) => {
  const dispatch = useAppDispatch();
  const isLoggedOut = !isLoggedIn;

  const {
    isVisibleNotificationPopover,
    isVisibleResumePopover,
    showNotificationPopover,
    hidePopover,
  } = usePopoverVisible(shouldShowResumePopup);

  const { handleLogin } = useLogin(currentPage, hasAdobeAnalyticsScripts);

  // お知らせ吹き出し表示処理
  const onClickOpen = (e) => {
    e.stopPropagation();
    showNotificationPopover();
    measureOnClickHeader({
      hasAdobeAnalyticsScripts,
      dispatch,
      measureTarget: MEASURE_ONCLICK_HEADER.newsOpen,
      setCookie: MEASURE_ONCLICK_HEADER.newsOpen.linkName,
    });
  };

  // お知らせ吹き出し非表示処理
  const onClickClose = () => {
    hidePopover();
    measureOnClickHeader({
      hasAdobeAnalyticsScripts,
      dispatch,
      measureTarget: MEASURE_ONCLICK_HEADER.newsClose,
      setCookie: MEASURE_ONCLICK_HEADER.newsClose.linkName,
    });
  };

  // ヘッダーのメニューアイコンのURL
  const {
    jobHistoryUrl,
    bookmarkListUrl,
    webResumeUrl,
    settingUrl,
    registrationUrl,
    logoutUrl,
  } = getIconMeneUrl(currentPage, isLoggedOut);

  return (
    <div className={styles.labeledIconMenu__wrapper}>
      {isLoggedIn && (
        <div className={styles.header__labeledIconWrapper}>
          <NotificationIcon
            onClick={
              isVisibleNotificationPopover
                ? onClickClose
                : (e) => onClickOpen(e)
            }
            budgeCount={badgeCount}
          />
          {isVisibleNotificationPopover && (
            <HeaderNotificationPopover
              notifications={notifications}
              onClose={onClickClose}
            />
          )}
        </div>
      )}
      <a
        href={jobHistoryUrl}
        onClick={() => {
          measureOnClickHeader({
            hasAdobeAnalyticsScripts,
            dispatch,
            measureTarget: MEASURE_ONCLICK_HEADER.viewHistory,
          });
        }}
      >
        <LabeledIcon
          label="閲覧履歴"
          icon={<BrowsingHistory size="XL" color="blue" />}
          budgeCount={browsingHistoryCount}
        />
      </a>
      <a
        href={bookmarkListUrl}
        onClick={() => {
          measureOnClickHeader({
            hasAdobeAnalyticsScripts,
            dispatch,
            measureTarget: MEASURE_ONCLICK_HEADER.consideration,
          });
        }}
      >
        <LabeledIcon
          label="気になる"
          icon={<Heart size="XL" color="blue" />}
          budgeCount={bookmarkCount}
        />
      </a>
      <div className={styles.header__labeledIconWrapper}>
        <a
          href={webResumeUrl}
          onClick={() => {
            measureOnClickHeader({
              hasAdobeAnalyticsScripts,
              dispatch,
              measureTarget: MEASURE_ONCLICK_HEADER.register,
            });
          }}
        >
          <LabeledIcon
            label="Web履歴書"
            icon={<Resume size="XL" color="blue" />}
            onClick={() => {
              measureOnClickHeader({
                hasAdobeAnalyticsScripts,
                dispatch,
                measureTarget: MEASURE_ONCLICK_HEADER.webResume,
              });
            }}
          />
        </a>
        {isVisibleResumePopover && (
          <HeaderResumePopover onClose={hidePopover} />
        )}
      </div>
      {isLoggedIn && (
        <>
          <a
            href={settingUrl}
            onClick={() => {
              measureOnClickHeader({
                hasAdobeAnalyticsScripts,
                dispatch,
                measureTarget: MEASURE_ONCLICK_HEADER.registerInfoSetting,
              });
            }}
          >
            <LabeledIcon label="登録情報設定" icon={<Setting size="XL" />} />
          </a>
          <a
            href={logoutUrl}
            onClick={() => {
              measureOnClickHeader({
                hasAdobeAnalyticsScripts,
                dispatch,
                measureTarget: MEASURE_ONCLICK_HEADER.logout,
              });
            }}
          >
            <LabeledIcon label="ログアウト" icon={<Logout size="XL" />} />
          </a>
        </>
      )}
      {isLoggedOut && (
        <>
          {currentPage !== HEADER_PAGES.JOB_SEARCH_LIST && (
            <a
              href={registrationUrl}
              onClick={() => {
                measureOnClickHeader({
                  hasAdobeAnalyticsScripts,
                  dispatch,
                  measureTarget: MEASURE_ONCLICK_HEADER.register,
                });
              }}
            >
              <LabeledIcon
                label="会員登録"
                icon={<Register size="XL" color="blue" />}
              />
            </a>
          )}
          <a href="/" onClick={handleLogin}>
            <LabeledIcon
              label="ログイン"
              icon={<Login size="XL" color="blue" />}
            />
          </a>
        </>
      )}
      {isLoggedOut && currentPage === HEADER_PAGES.JOB_SEARCH_LIST && (
        <a
          href={registrationUrl}
          onClick={() => {
            measureOnClickHeader({
              hasAdobeAnalyticsScripts,
              dispatch,
              measureTarget: MEASURE_ONCLICK_HEADER.register,
            });
          }}
        >
          <LabeledIcon
            label="会員登録"
            icon={<Register size="XL" color="white" />}
            isOrange
          />
        </a>
      )}
    </div>
  );
};
