import { ValueOf } from '@doda/common/src/utils/typeUtil';

/**
 * ヘッダーのナビゲーションメニュー名
 */
export const HEADER_NAV_MENU = {
  SEARCH: 'SEARCH',
  REFER: 'REFER',
  OFFER: 'OFFER',
  HISTORY: 'HISTORY',
  GUIDE: 'GUIDE',
  EVENT: 'EVENT',
} as const;

export type HeaderNavMenu = ValueOf<typeof HEADER_NAV_MENU>;

export const HEADER_PAGES = {
  TOP: 'TOP',
  JOB_SEARCH_LIST: 'JOB_SEARCH_LIST',
  BOOKMARK_LIST: 'BOOKMARK_LIST',
  OTHER: 'OTHER',
} as const;

// ページごとのパラメータ
export const HEADER_PAGE_PARAM = {
  TOP: {
    USRCLK: 'usrclk',
    logoParam: '_commonHeader_logo',
    menuParam: '_commonHeader_',
    originParam: '_globalNavi_',
  },
  JOB_SEARCH_LIST: {
    USRCLK: 'usrclk_searchList',
    logoParam: 'JobSearchList_commonHeader_logo',
    menuParam: 'JobSearchList_commonHeader_',
    originParam: 'JobSearchList_globalNavi_',
  },
  BOOKMARK_LIST: {
    USRCLK: 'usrclk_kininaruList',
    logoParam: 'KininaruListMediaScout_commonHeader_logo',
    menuParam: 'KininaruListMediaScout_commonHeader_',
    originParam: 'KininaruListMediaScout_globalNavi_',
  },
} as const;

// ヘッダーアイコンのパラメータ
export const HEADER_ICON_PARAM = {
  JOB_HISTORY_LIST: 'jobHistoryList',
  BOOKMARK_LIST: 'bookmarkList',
  WEB_RESUME: {
    TOP: 'mypageWebResume',
    JOB_HISTORY_LIST_AND_BOOKMARK_LIST: 'webResume',
  },
  REGISTER: 'memberRegist',
  LOGIN: 'login',
  SETTING: {
    TOP: 'mypageRegistSetting',
    JOB_HISTORY_LIST_AND_BOOKMARK_LIST: 'registSetting',
  },
  LOGOUT: 'logout',
} as const;

// ヘッダーのナビゲーションの項目
export const HEADER_NAV_MENU_ITEM = {
  SEARCH: {
    title: '求人を探す',
    href: '/',
    DROP_DOWN_MENU: {
      DETAIL: {
        title: '詳細条件から探す',
        href: '/DodaFront/View/JobSearchTop/j_from_global_navi__True/',
        pageParam: 'kyujinSearch_detail',
      },
      NEW: {
        title: '新着求人から探す',
        href: '/DodaFront/View/NewJobList.action',
        pageParam: 'kyujinSearch_newArrival',
      },
      FEATURE: {
        title: '求人特集から探す',
        href: '/JobSearchPickUp.html',
        pageParam: 'kyujinSearch_featured',
      },
    },
  },
  REFER: {
    title: '求人を紹介してもらう',
    href: '/refer/',
    DROP_DOWN_MENU: {
      AGENT: {
        title: 'エージェントサービス',
        href: '/consultant/',
        pageParam: 'kyujinIntroduction_agent',
      },
      SCOUT: {
        title: 'スカウトサービス',
        href: '/scout/',
        pageParam: 'kyujinIntroduction_scout',
      },
      PARTNER: {
        title: 'パートナーエージェントサービス',
        href: '/partneragent/',
        pageParam: 'kyujinIntroduction_partneragent',
      },
    },
  },
  OFFER: {
    title: '紹介求人を見る',
    href: '#',
    DROP_DOWN_MENU: {
      CAREER_LIST: {
        title: 'キャリアアドバイザー紹介求人',
        href: '/dcfront/referredJob/referredJobList/',
        pageParam: 'kyujinOffer_caOffer',
      },
      PROJECT_LIST: {
        title: '採用プロジェクト担当紹介求人',
        href: '/dcfront/referredJob/saiyoprojectList/',
        pageParam: 'kyujinOffer_plusOffer',
      },
      INTERVIEW_LIST: {
        title: '企業からのオファー',
        href: '/dcfront/referredJob/interviewOfferList/',
        pageParam: 'kyujinOffer_clientOffer',
      },
      PARTNER_SCOUT_LIST: {
        title: 'パートナーエージェントからのスカウト',
        href: '/dcfront/referredJob/mapsScoutList/',
        pageParam: '',
      },
    },
  },
  HISTORY: {
    title: '選考状況を見る',
    href: '/dcfront/history/historyList/',
    DROP_DOWN_MENU: {
      TODO_LIST: {
        title: 'やることリスト',
        href: '/dcfront/progress/progressTop/',
        pageParam: 'selectionStatus_progressTop',
      },
      SELECTION_STATUS: {
        title: '応募・選考状況',
        href: '/dcfront/history/historyList/',
        pageParam: 'selectionStatus_historyList',
      },
    },
  },
  GUIDE: {
    title: '知りたい・聞きたい',
    href: '/guide/',
    DROP_DOWN_MENU: {
      KNOWLEDGE: {
        title: '転職ノウハウ',
        href: '/guide/#movingPosition01',
        pageParam: 'knowhow_knowhow#movingPosition01',
      },
      DATA: {
        title: 'データで見る転職',
        href: '/guide/cyousatai/',
        pageParam: 'knowhow_tenshokuData',
      },
      COLUMN_INTERVIEW: {
        title: 'コラム・インタビュー',
        href: '/guide/#movingPosition03',
        pageParam: 'knowhow_interview#movingPosition03',
      },
      QUESTION_ANSWER: {
        title: '転職Q&A',
        href: '/guide/qa/',
        pageParam: 'knowhow_qa',
      },
    },
  },
  EVENT: {
    title: 'イベント',
    href: '/event/',
    DROP_DOWN_MENU: {
      EVENT_SEMINAR: {
        title: '転職イベント・転職セミナー',
        href: '/event/',
        pageParam: 'event_eventList',
      },
      FAIR: {
        title: '転職フェア',
        href: '/event/fair/',
        pageParam: 'event_fair',
      },
      SEMINAR_MOVIE: {
        title: '転職セミナー動画',
        href: '/event/webmovie/',
        pageParam: 'event_webmovie',
      },
    },
  },
} as const;
