import { FC, MouseEventHandler } from 'react';
import { Notification } from '@doda/io-react/common';
import styles from './index.module.scss';

/** NotificationIcon Props*/
interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  budgeCount: number;
}

export const NotificationIcon: FC<Props> = ({ onClick, budgeCount }) => {
  const showBudgeCount = budgeCount > 0;

  return (
    <button
      type="button"
      className={styles.notificationIcon__wrapper}
      onClick={onClick}
    >
      <div className={styles.notificationIcon__iconWrapper}>
        <Notification size="XL" />
      </div>
      <div className={styles.notificationIcon__label}>お知らせ</div>
      {showBudgeCount && (
        <div className={styles.notificationIcon__budgeCount}>{budgeCount}</div>
      )}
    </button>
  );
};
