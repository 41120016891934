/**
 * クッキーを取得する
 * @param {string} name
 */
export function getCookie(name: string): string {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`
    )
  );
  return matches ? decodeURIComponent(matches[1]) : '';
}

/**
 * クッキーのパラメータ文字列をマップに変換して返す
 * @param {string} name
 */
export function getCookieMap(name: string): Record<string, string> | null {
  const params = getCookie(name);
  if (!params) return null;
  return params
    .split('&')
    .map((p) => p.split('='))
    .reduce((obj, e) => ({ ...obj, [e[0]]: e[1] }), {});
}

interface CookieOptions {
  expires?: Date | string;
  domain?: string;
  path?: string;
  ['max-age']?: number;
}

/**
 * クッキーの設定
 * @param {string} name
 * @param {string} value
 * @param {object} options
 */
export function setCookie(
  name: string,
  value: string,
  options: CookieOptions
): void {
  if (options && !('path' in options)) {
    options.path = '/'; // デフォルト値
  }

  if (
    options &&
    'expires' in options &&
    (options.expires as Date).toUTCString
  ) {
    options.expires = (options.expires as Date).toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value
  )}`;

  Object.keys(options).forEach((key) => {
    updatedCookie += `; ${key}`;
    const optionValue = options[key];
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  });

  document.cookie = updatedCookie;
}

/**
 * クッキーを削除する
 * @param {string} name
 */
export function deleteCookie(name: string): void {
  setCookie(name, '', {
    'max-age': -1,
  });
}

/**
 * 指定したクッキーを取得する
 * @param {string} name
 */
export function getTargetCookie(cookieString: string, name: string): string {
  const matches = cookieString.match(
    new RegExp(
      `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`
    )
  );
  return matches ? decodeURIComponent(matches[1]) : '';
}
