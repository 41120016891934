import React, { FC } from 'react';
import { NotificationInfoItem as NotificationInfoItemType } from '@doda/common/src/interfaces/api/notificationInfo';
import styles from './index.module.scss';
import { Popover } from '../Popover';
import { NotificationList } from '../NotificationList';

/** HeaderNotificationPopoverコンポーネントのプロパティ */
interface Props {
  /** 通知のリスト */
  notifications: NotificationInfoItemType[];
  /** ポップオーバーを閉じるためのコールバック関数 */
  onClose: () => void;
}

/**
 * HeaderNotificationPopoverコンポーネント
 *
 * @param {Props} props - コンポーネントのプロパティ
 * @param {NotificationInfoItemType[]} props.notifications - 通知のリスト
 * @param {() => void} props.onClose - ポップオーバーを閉じるためのコールバック関数
 * @returns {JSX.Element} - HeaderNotificationPopoverコンポーネント
 */
export const HeaderNotificationPopover: FC<Props> = ({
  notifications,
  onClose,
}) => {
  const hasNotifications = notifications.length > 0;
  return (
    <Popover
      onClose={onClose}
      isNotificationPopover
      positionLeft="10px"
      arrowPositionLeft="15px"
      height="520px"
    >
      <div className={styles.headerNotificationPopover__wrapper}>
        {hasNotifications ? (
          <div className={styles.headerNotificationPopover__scrollableWrapper}>
            <NotificationList notifications={notifications} />
          </div>
        ) : (
          <p
            className={styles.headerNotificationPopover__noNotificationMessage}
          >
            現在、お知らせはありません。
          </p>
        )}
      </div>
    </Popover>
  );
};
