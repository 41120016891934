import {
  NotificationInfo,
  NotificationInfoItem,
} from '@doda/common/src/interfaces/api/notificationInfo';
import { LocalStorageUtil } from '@doda/common/src/utils/localStorageUtil';

// TODO: リファクタリングする
/**
 * お知らせ詳細情報取得
 * @param {NotificationInfo} お知らせ情報
 * @returns {NotificationInfoItem} お知らせ詳細情報
 * @returns {badgeCount} 未読のお知らせ件数
 * @returns {boolean} データが無い場合、trueが返却される
 */
export const getNotificationDetailList = (
  notificationInfo: NotificationInfo
) => {
  let badgeCount = 0; // 表示件数
  let limitDate: string | number; // お知らせ表示閾値
  let storageTimeList; // 未読既読用最終閲覧時リスト
  let savedInfo; // 保存済み通知内容
  let notificationInfoList: NotificationInfoItem[] | [] = []; // 処理用リスト
  const notificationInfoStorageList: NotificationInfoItem[] = []; // 保存用リスト

  if (notificationInfo && notificationInfo.deleteStorageFlg) {
    LocalStorageUtil.removeItem('header_notificationInfo');
    LocalStorageUtil.removeItem('header_notificationInfoList');
  } else {
    storageTimeList = LocalStorageUtil.getItem('header_notificationInfo'); // 未読既読用最終閲覧時リスト
    savedInfo = LocalStorageUtil.getItem('header_notificationInfoList'); // 保存済み通知内容
  }

  // お知らせ閾値情報の取得
  if (notificationInfo && notificationInfo.limitDate) {
    limitDate = notificationInfo.limitDate; // お知らせ表示閾値
  }

  if (notificationInfo && !notificationInfo.notGetFlg) {
    // 実行時間の保存
    if (storageTimeList) {
      storageTimeList.lastTimeImmediate = notificationInfo.resImmediateTime;
      storageTimeList.lastTimeRegMain = notificationInfo.resRegMainTime;
      storageTimeList.lastTimeRegOther = notificationInfo.resRegOtherTime;
      storageTimeList.resumeUploadDispTime =
        notificationInfo.resumeUploadDispTime;
      storageTimeList.wsfid = notificationInfo.wsfid;
    } else {
      storageTimeList = {
        lastTimeImmediate: notificationInfo.resImmediateTime,
        lastTimeRegMain: notificationInfo.resRegMainTime,
        lastTimeRegOther: notificationInfo.resRegOtherTime,
        resumeUploadDispTime: notificationInfo.resumeUploadDispTime,
        wsfid: notificationInfo.wsfid,
      };
    }
    LocalStorageUtil.setItem('header_notificationInfo', storageTimeList);

    // 取得情報と保存済情報を結合
    if (!notificationInfo.notificationInfo && !savedInfo) {
      return true;
    }
    if (!notificationInfo.notificationInfo) {
      notificationInfoList = savedInfo;
    } else if (savedInfo) {
      notificationInfoList =
        notificationInfo.notificationInfo.concat(savedInfo);
    } else {
      notificationInfoList = notificationInfo.notificationInfo;
    }
  } else if (savedInfo) {
    notificationInfoList = savedInfo;
  }

  // === 新機能追加のお知らせ追加処理 ===
  // 既に「新機能追加のお知らせ」が表示されている場合は除去する
  let filteredList: NotificationInfoItem[] | [] = [];
  let announcementReadedFlg: boolean | null = false;
  if (notificationInfoList.length > 0) {
    filteredList = notificationInfoList.filter((item) => {
      if (item.infoType === 'newFeatureAnnouncement') {
        // 既読判定を保持
        announcementReadedFlg = item.readedFlg;
        return false;
      }
      return true;
    });
  }
  // 新機能追加のお知らせを追加
  const newFeatureAnnouncementInfo: NotificationInfoItem[] | [] = [
    {
      category: '9',
      entryDate: '2023/06/20 12:00',
      infoType: 'newFeatureAnnouncement',
      judge: 'AddedCalendarFunction',
      readedFlg: announcementReadedFlg,
      sortNum: 0,
      text: '選考スケジュールをまとめて管理できるカレンダー機能が追加されました。',
      url: '/attention/20230620.html/',
    },
  ];
  notificationInfoList = newFeatureAnnouncementInfo.concat(filteredList);
  // === ここまで 新機能追加のお知らせ追加処理 ===

  if (notificationInfoList.length > 0) {
    notificationInfoList.every((elm, idx) => {
      const info = { ...elm };
      if (idx > 300) return false;
      if (info.entryDate && info.entryDate < limitDate) {
        return true;
      }
      // 各画面の最終閲覧時間を格納
      let storageTime = '';

      // 未読既読判定用
      if (info.infoType && storageTimeList[info.infoType]) {
        storageTime = storageTimeList[info.infoType];
      }
      if (info.readedFlg) {
        info.readedFlg = true;
      } else if (info.judge && storageTime > info.judge) {
        info.readedFlg = true;
      } else {
        info.readedFlg = false;
      }
      // 既読の場合背景色を設定 未読の場合件数をカウント
      if (!info.readedFlg) {
        badgeCount += 1;
      }
      notificationInfoStorageList.push(info);
      return true;
    });
    if (notificationInfoStorageList.length > 0) {
      LocalStorageUtil.setItem(
        'header_notificationInfoList',
        notificationInfoStorageList
      );
    }
  }

  return { notificationInfoStorageList, badgeCount };
};
