import { NotificationInfoItem } from '@doda/common/src/interfaces/api/notificationInfo';
import { getNotificationDetailList } from '@doda/common/src/logics/notification/getNotificationDetailList';
import { memberInfoSelector } from '@doda/common/src/redux/features/memberInfo';
import { useAppSelector } from '@doda/common/src/redux/hooks';
import { getNotificationInfo } from '@doda/common/src/services/notificationInfoAPI';
import {
  HeaderNotificationInfoType,
  LocalStorageUtil,
} from '@doda/common/src/utils/localStorageUtil';
import { useEffect, useState } from 'react';

/**
 * ヘッダーに表示する通知情報を管理するカスタムフック
 * @returns {{
 *   notifications: NotificationInfoItem[];
 *   notificationBadgeCount: number;
 *   memberInfo: ReturnType<typeof memberInfoSelector>;
 *   partnerAgentServiceFlag: boolean;
 *   setNotificationBadgeCount: React.Dispatch<React.SetStateAction<number>>;
 *   setNotifications: React.Dispatch<React.SetStateAction<NotificationInfoItem[]>>;
 * }}
 */
export const useHeader = () => {
  const [notifications, setNotifications] = useState<NotificationInfoItem[]>(
    []
  );
  const [notificationBadgeCount, setNotificationBadgeCount] =
    useState<number>(0);

  const memberInfo = useAppSelector(memberInfoSelector);
  // パートナーエージェントサービス利用フラグ（whtrfl=2が利用中）
  const partnerAgentServiceFlag: boolean = memberInfo?.whtrfl === 2;

  useEffect(() => {
    (async () => {
      if (memberInfo?.loginStatus) {
        const storageTimeList = LocalStorageUtil.getItem(
          'header_notificationInfo'
        ) as HeaderNotificationInfoType | undefined;

        const params = {
          lastTimeImmediate: storageTimeList?.lastTimeImmediate ?? '',
          lastTimeRegMain: storageTimeList?.lastTimeRegMain ?? '',
          lastTimeRegOther: storageTimeList?.lastTimeRegOther ?? '',
          resumeUploadDispTime: storageTimeList?.resumeUploadDispTime ?? '',
          wsfid: storageTimeList?.wsfid ?? '',
        };
        const notificationInfo = await getNotificationInfo({ params });
        const notificationItems = getNotificationDetailList(notificationInfo);

        // Note: 戻り値がtrueでない場合（データがある場合）、以下の分岐に入る（getNotificationDetailListの処理の条件分岐を既存のままにする為の処置）
        if (notificationItems !== true) {
          setNotifications(notificationItems.notificationInfoStorageList);
          setNotificationBadgeCount(notificationItems.badgeCount);
        }
      }
    })();
  }, [memberInfo?.loginStatus]);

  return {
    notifications,
    notificationBadgeCount,
    memberInfo,
    partnerAgentServiceFlag,
    setNotificationBadgeCount,
    setNotifications,
  };
};
