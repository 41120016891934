import { FC } from 'react';
import { HeaderLogo, HeaderNavigation, HeaderTitle } from '@doda/io-react/pc';
import { Text } from '@doda/io-react/common';
import { NotificationInfoItem as NotificationInfoItemType } from '@doda/common/src/interfaces/api/notificationInfo';
import { DEFAULT_LEAD_SENTENCE } from 'constants/headerLeadSentence';
import parse from 'html-react-parser';
import { getNavMenu } from './logics/navigationMenu';
import styles from './index.module.scss';
import { LabeledIconMenu } from './components/LabeledIconMenu';
import { HeaderNavMenu, HEADER_PAGES } from '../../constants/headerNavMenu';
import { createLogoUrl } from './logics/createLogoUrl';
import { HeaderPageType } from './interface';

/**
 * HeaderのProps
 */
interface Props {
  isLoggedIn: boolean;
  leadSentence?: string;
  browsingHistoryCount: number;
  bookmarkCount: number;
  partnerAgentServiceFlag: boolean;
  notifications: NotificationInfoItemType[];
  userName?: string;
  currentPage?: HeaderPageType['currentPage'];
  badgeCount: number;
  selectedNavItem?: HeaderNavMenu;
  shouldShowResumePopup: boolean;
  hasAdobeAnalyticsScripts: boolean;
}

export const Header: FC<Props> = ({
  isLoggedIn,
  leadSentence,
  browsingHistoryCount,
  bookmarkCount,
  partnerAgentServiceFlag,
  notifications,
  userName,
  currentPage = HEADER_PAGES.OTHER,
  badgeCount,
  selectedNavItem,
  shouldShowResumePopup,
  hasAdobeAnalyticsScripts,
}) => {
  const isLoggedOut = !isLoggedIn;
  const strLoginStatus = isLoggedIn ? 'login' : 'logout';
  const headerLogoUrl = createLogoUrl(strLoginStatus, currentPage);
  const navItems = getNavMenu(
    isLoggedIn,
    partnerAgentServiceFlag,
    currentPage,
    selectedNavItem
  );

  return (
    <header className={styles.header}>
      <div className={styles.header__contentWrapper}>
        <div className={styles.header__topRow}>
          <div className={styles.header__logoWrapper}>
            <HeaderLogo
              imgUrl="https://doda.jp/cmn_web/img/logo_doda/logo_slogan_bg_195x52_endorse.png"
              alt="はたらく今日が、いい日に。"
              href={headerLogoUrl}
            />
            <div className={styles.header__titleWrapper}>
              <HeaderTitle
                title={String(parse(leadSentence ?? DEFAULT_LEAD_SENTENCE))}
              />
            </div>
          </div>
          <div className={styles.header__iconSectionWrapper}>
            {isLoggedOut && currentPage === HEADER_PAGES.TOP && (
              <a
                href="https://www.saiyo-doda.jp/lp/tm/main/?utm_medium=referral&usrclk=PC_logout_commonHeader_toClient&utm_source=doda.jp&utm_campaign=doda.jp_topheader&cid=referral_dodajp_topheader_00_00_00"
                className="header__forEmployersLink"
                rel="noreferrer"
                target="_blank"
              >
                <Text color="blue" size="11">
                  中途採用を検討中の企業さまへ
                </Text>
              </a>
            )}
            {isLoggedIn && userName && (
              <div className={styles.header_userNameWrapper}>
                <Text color="black_pc" size="11">
                  {userName} さん
                </Text>
              </div>
            )}
            <LabeledIconMenu
              isLoggedIn={isLoggedIn}
              browsingHistoryCount={browsingHistoryCount}
              bookmarkCount={bookmarkCount}
              notifications={notifications}
              badgeCount={badgeCount}
              shouldShowResumePopup={shouldShowResumePopup}
              hasAdobeAnalyticsScripts={hasAdobeAnalyticsScripts}
              currentPage={currentPage}
            />
          </div>
        </div>
        <HeaderNavigation navMenu={navItems} />
      </div>
    </header>
  );
};
