/* eslint-disable no-underscore-dangle */
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import { QueryParams } from '@doda/common/src/constants/queryParams';
import { createParam } from './parameterUtil';
import { unescapeHtml } from './escapeHtmlUtil';
import {
  FIRST_CANONICAL_PARAM_REGEX,
  SUBSEQUENT_CANONICAL_PARAM_REGEX,
} from '../constants/regex';
import { isCanonical } from './seoUtil';
import { decodeKeyword } from './decodeUtil';

/**
 * URLクエリパラメーター値の取得
 * @param name - パラメータのキー文字列
 * @param url
 * @return 対象のURL文字列
 */
export const getParam = (name: string, url) => {
  if (!url) url = '';
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

/**
 * URLクエリパラメーター値の取得
 * @param url - クエリパラメーターを含む文字列
 * @return 対象のURL文字列
 */
export const getParamsObject = (
  url: string
): { newHref: string; paramsObject: Record<string, string> } => {
  const search = url.includes('?') ? url.substring(url.indexOf('?')) : '';
  let paramsObject: Record<string, string> = {};
  try {
    paramsObject = JSON.parse(
      `{"${decodeURI(search.substring(1))
        .replace(/'/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`
    );
  } catch (error) {
    paramsObject = {};
  }

  return {
    newHref: url.replace(search, ''),
    paramsObject,
  };
};

/**
 * URL末尾hash値の生成
 * @param hash  hash文字列
 */
function createHash(hash?: string) {
  if (!hash) return '';
  return `#${hash}`;
}

interface CreateUrlProps {
  href: string;
  gaParam?: string | null;
  params?: Record<string, string | number | boolean | null>;
  usrclk?: Record<string, string>;
  hash?: string;
}

/**
 * URL生成
 * @param href url
 * @param gaParam gaパラメータ
 * @param params クエリパラメーター生成用データ
 * @param hash hash
 * @returns 生成URL
 */
export const createUrl = ({
  params,
  usrclk,
  gaParam,
  hash,
  href,
}: CreateUrlProps) => {
  const { path, param: hrefParam, hash: hrefHash } = getUrlMap(href);
  const param = { ...hrefParam, ...params, ...usrclk };
  if (gaParam) param._ga = gaParam;

  // キーワードが存在する場合、キーワードをデコードする
  if (typeof param.k === 'string') {
    const keywordParam = decodeKeyword(param.k);
    if (keywordParam) param.k = keywordParam;
  }

  const newParam = Object.keys(param).length > 0 ? createParam(param) : '';
  const newHash = createHash(hrefHash || hash);

  return path + newParam + newHash;
};

/**
 * URLを分解しマップに変換する
 * @param url
 * @returns 変換したURL情報
 */
const getUrlMap = (
  url: string
): { path: string; param: Record<string, string>; hash: string } => {
  const path = url.includes('?') ? url.split('?')[0] : url.split('#')[0];
  const param = unescapeHtml(url)
    .split(/[?&#]/)
    .filter((v) => v.includes('='))
    .map((v) => v.split('='))
    .reduce((obj, e) => ({ ...obj, [e[0]]: e[1] }), {});
  const hash = url.split('#')[1];

  return { path, param, hash };
};

/**
 * 正規化URLからjobidを取得
 * @param {NextParsedUrlQuery} query 正規化URL url
 * @returns {string} 生成URL
 */
export const getJobId = (query: NextParsedUrlQuery) => {
  const { jid, path } = query;
  if (jid) {
    const jidStr = Array.isArray(jid) ? jid[0] : jid;
    // jidは0以上の整数10桁の文字列
    return /^([0-9]{10})$/.test(jidStr) ? jidStr : '';
  }
  const prefix = 'j_jid__';
  if (Array.isArray(path)) {
    return (
      path.filter((str) => str.includes(prefix))[0]?.split(prefix)[1] ?? ''
    );
  }
  if (typeof path === 'string') {
    return path.split(prefix)[1] ?? '';
  }
  return '';
};

/**
 * 正規化URLから対象のparamを取得
 * @param {NextParsedUrlQuery} query 正規化URL
 * @param {targetKey} key queryのkey
 * @returns {string | undefined} value queryのvalue
 */
export const getParamFromCanonicalURl = (
  query: NextParsedUrlQuery,
  key: keyof QueryParams
): string | undefined => {
  const value = query[key];
  if (Array.isArray(value)) {
    return value[0];
  }
  return value;
};

/**
 * canonical形式の特定のパラメータを更新する関数
 * パラメータが存在しない場合は追加される
 * @param {string} baseUrl - 操作するURL
 * @param {string} paramName - 更新または追加するパラメータ名
 * @param {string} paramValue - パラメータの新しい値
 * @returns {string} 更新されたURL
 */
const updateCanonicalUrlParam = (
  baseUrl: string,
  paramName: string,
  paramValue: string
): string => {
  // 正規表現と置換文字列の組を定義
  const patterns = [
    {
      regex: new RegExp(`(/j_)${paramName}__[^/]+/`),
      replacement: `/j_${paramName}__${paramValue}/`,
    },
    {
      regex: new RegExp(`(/-)${paramName}__[^/]+/`),
      replacement: `/-${paramName}__${paramValue}/`,
    },
  ];

  // eslint-disable-next-line no-restricted-syntax
  for (const { regex, replacement } of patterns) {
    if (regex.test(baseUrl)) {
      return baseUrl.replace(regex, replacement);
    }
  }

  // パラメータが存在しない場合は新たに追加
  const hasTrailingSlash = baseUrl.endsWith('/');
  return hasTrailingSlash
    ? `${baseUrl}-${paramName}__${paramValue}/`
    : `${baseUrl}/-${paramName}__${paramValue}/`;
};

/**
 * URLのパスとクエリストリングを取得する関数
 * @param {string} url - 元のURL
 * @returns {object} {baseUrl: string, queryString: string}
 */
const splitUrl = (url: string): { baseUrl: string; queryString: string } => {
  const [baseUrl, queryString = ''] = url.split('?');
  return { baseUrl, queryString };
};

/**
 * URLの特定のパラメータを更新または追加する関数
 * URLがcanonical形式かクエリ形式かに応じて適切な更新処理を行う
 * @param {string} url - 操作するURL
 * @param {string} paramName - 更新または追加するパラメータ名
 * @param {string} paramValue - パラメータの新しい値
 * @returns {string} 更新されたURL
 */
export const updateUrlWithParam = (
  url: string,
  paramName: string,
  paramValue: string
): string => {
  const { baseUrl, queryString } = splitUrl(url);
  if (isCanonical(baseUrl)) {
    const updatedBaseUrl = updateCanonicalUrlParam(
      baseUrl,
      paramName,
      paramValue
    );
    return queryString ? `${updatedBaseUrl}?${queryString}` : updatedBaseUrl;
  }

  // クエリパラメータの場合
  return createUrl({ href: url, params: { [paramName]: paramValue } });
};

/**
 * カノニカルURLの複数のパラメータを削除する関数
 * @param {string} url - 操作するURL
 * @param {string[]} paramNames - 削除するパラメータ名の配列
 * @returns {string} 更新されたURL
 */
const removeCanonicalUrlParams = (
  url: string,
  paramNames: string[]
): string => {
  let updatedUrl = url;
  paramNames.forEach((paramName) => {
    // 最初のパラメータの形式の正規表現
    const regexFirstParam = new RegExp(`/j_${paramName}__[^/]+/`);
    // 2番目以降のパラメータの形式の正規表現
    const regexOtherParam = new RegExp(`/-${paramName}__[^/]+/`);

    // 最初のパラメータ形式の場合の削除
    updatedUrl = updatedUrl.replace(regexFirstParam, '/');
    // 2番目以降のパラメータの形式の場合の削除
    updatedUrl = updatedUrl.replace(regexOtherParam, '/');
  });

  // 最初のj_パラメータが無い場合は、最初の`-paramName__value`を見つけて`j_`に置き換える
  if (!FIRST_CANONICAL_PARAM_REGEX.test(updatedUrl)) {
    updatedUrl = updatedUrl.replace(SUBSEQUENT_CANONICAL_PARAM_REGEX, '/j_$1');
  }

  return updatedUrl;
};
/**
 * 複数のクエリパラメータを削除する関数
 * @param {string} url - 操作するURL
 * @param {string[]} paramsToRemove - 削除するパラメータ名の配列
 * @returns {string} 更新されたURL
 */
const removeQueryUrlParams = (
  url: string,
  paramsToRemove: string[]
): string => {
  const [baseUrl, queryString] = url.split('?');
  if (!queryString) return baseUrl; // クエリパラメータが存在しない場合、ベースURLを返す

  // 指定されたパラメータを除外
  const removedQueryString = queryString
    .split('&')
    .filter((query) => !paramsToRemove.includes(query.split('=')[0]))
    .join('&');

  return removedQueryString ? `${baseUrl}?${removedQueryString}` : baseUrl;
};

/**
 * URLの複数のパラメータを削除する関数。
 * URLがcanonical形式かクエリ形式かに応じて適切な更新処理を行う
 * @param {string} url - 操作するURL
 * @param {string[]} paramNames - 削除するパラメータ名の配列
 * @returns {string} 更新されたURL
 */
export const removeUrlParams = (url: string, paramNames: string[]): string => {
  const removedCanonicalUrl = removeCanonicalUrlParams(url, paramNames);
  const removedParamsUrl = removeQueryUrlParams(
    removedCanonicalUrl,
    paramNames
  );
  return removedParamsUrl;
};
