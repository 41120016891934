import { FC, ReactNode } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

/**
 * LabeledIconコンポーネントに渡されるプロパティを定義するインターフェース
 */
interface Props {
  /** アイコンのラベル */
  label: string;
  /** アイコンとして表示される要素 */
  icon: ReactNode;
  /** クリックイベントハンドラー（オプション） */
  onClick?: (e) => void;
  /** 表示されるバッジのカウント（オプション） */
  budgeCount?: number;
  /** オレンジ色のバッジかどうかを決定するフラグ（オプション） */
  isOrange?: boolean;
}

/**
 * LabeledIconはラベルとアイコンを表示するボタンコンポーネント
 *
 * @param {Props} props - コンポーネントに渡されるプロパティ
 * @returns {JSX.Element} - アイコンとラベルが含まれたボタン要素
 */
export const LabeledIcon: FC<Props> = ({
  label,
  icon,
  onClick,
  budgeCount,
  isOrange = false,
}) => {
  const showBudgeCount = budgeCount !== undefined && budgeCount > 0;
  const wrapperClassNames = classnames(styles.labeledIcon__wrapper, {
    'labeledIcon__wrapper--orange': isOrange,
  });
  const displayBudgeCount =
    budgeCount !== undefined && budgeCount > 99 ? '99+' : budgeCount;
  return (
    <button
      type="button"
      key={label}
      className={wrapperClassNames}
      onClick={onClick}
    >
      <div className={styles.labeledIcon__iconWrapper}>{icon}</div>
      <div className={styles.labeledIcon__label}>{label}</div>
      {showBudgeCount && (
        <div className={styles.labeledIcon__budgeCount}>
          {displayBudgeCount}
        </div>
      )}
    </button>
  );
};
