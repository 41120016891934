export const COOKIE = {
  JSESSIONID: 'JSESSIONID',
  CSP_SESSION: 'csp_session',
  AWSALB: 'AWSALB',
  AWSALBCORS: 'AWSALBCORS',
  BOOKMARKID: 'BOOKMARKID',
  HISTORYID: 'HISTORYID',
  HISTORY_ID_CA: 'HISTORY_ID_CA',
  DESIRED_COND: 'desired_cond',
  DESIRED_SEARCH: 'desired_search',
  TUTOR_MODAL: 'tutorModal',
  SESSION: 'SESSION',
  SEARCH_CONDITION1: 'search_condition1',
  SEARCH_CONDITION2: 'search_condition2',
  SEARCH_CONDITION3: 'search_condition3',
  LATEST_SEARCH: 'latest_search',
  VIEW_MODE: 'viewmode',
};
