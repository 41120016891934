import { ComponentProps } from 'react';
import { HeaderNavigation } from '@doda/io-react/pc';
import { HeaderPageType } from 'components/Header/interface';
import {
  HEADER_NAV_MENU,
  HEADER_NAV_MENU_ITEM,
  HEADER_PAGES,
  HeaderNavMenu,
} from '../../../../constants/headerNavMenu';
import { createNavItemUrl } from '../createNavItemUrl';

type NavItem = ComponentProps<typeof HeaderNavigation>['navMenu'][number];

/**
 * ヘッダーナビゲーションのメニュー項目を取得する関数
 * @param {boolean} isLoggedIn - ユーザーがログイン中かどうか
 * @param {boolean} partnerAgentServiceFlag - パートナーエージェントサービスフラグ
 * @param {HeaderPageType['currentPage']} currentPage - 現在のページ
 * @param {HeaderNavMenu} [selected] - 選択されたナビゲーションメニュー
 * @returns {NavItem[]} ヘッダーナビゲーションのメニュー項目の配列を返します
 */
export const getNavMenu = (
  isLoggedIn: boolean,
  partnerAgentServiceFlag: boolean,
  currentPage: HeaderPageType['currentPage'],
  selected?: HeaderNavMenu
): NavItem[] => {
  const isLoggedOut = !isLoggedIn;
  const strLoginStatus = isLoggedIn ? 'login' : 'logout';

  return [
    {
      text: HEADER_NAV_MENU_ITEM.SEARCH.title,
      href: HEADER_NAV_MENU_ITEM.SEARCH.href,
      dropdownMenu: [
        {
          href: createNavItemUrl(
            strLoginStatus,
            currentPage,
            HEADER_NAV_MENU_ITEM.SEARCH.DROP_DOWN_MENU.DETAIL.href,
            HEADER_NAV_MENU_ITEM.SEARCH.DROP_DOWN_MENU.DETAIL.pageParam
          ),
          onClick: function noRefCheck() {},
          text: HEADER_NAV_MENU_ITEM.SEARCH.DROP_DOWN_MENU.DETAIL.title,
        },
        {
          href: createNavItemUrl(
            strLoginStatus,
            currentPage,
            HEADER_NAV_MENU_ITEM.SEARCH.DROP_DOWN_MENU.NEW.href,
            HEADER_NAV_MENU_ITEM.SEARCH.DROP_DOWN_MENU.NEW.pageParam
          ),
          onClick: function noRefCheck() {},
          text: HEADER_NAV_MENU_ITEM.SEARCH.DROP_DOWN_MENU.NEW.title,
        },
        {
          href: createNavItemUrl(
            strLoginStatus,
            currentPage,
            HEADER_NAV_MENU_ITEM.SEARCH.DROP_DOWN_MENU.FEATURE.href,
            HEADER_NAV_MENU_ITEM.SEARCH.DROP_DOWN_MENU.FEATURE.pageParam
          ),
          onClick: function noRefCheck() {},
          text: HEADER_NAV_MENU_ITEM.SEARCH.DROP_DOWN_MENU.FEATURE.title,
        },
      ],
      selected: selected === HEADER_NAV_MENU.SEARCH,
    },
    ...(isLoggedOut
      ? [
          {
            text: HEADER_NAV_MENU_ITEM.REFER.title,
            href:
              currentPage === HEADER_PAGES.TOP
                ? HEADER_NAV_MENU_ITEM.REFER.href
                : '#',
            dropdownMenu: [
              {
                href: createNavItemUrl(
                  strLoginStatus,
                  currentPage,
                  HEADER_NAV_MENU_ITEM.REFER.DROP_DOWN_MENU.AGENT.href,
                  HEADER_NAV_MENU_ITEM.REFER.DROP_DOWN_MENU.AGENT.pageParam
                ),
                onClick: function noRefCheck() {},
                text: HEADER_NAV_MENU_ITEM.REFER.DROP_DOWN_MENU.AGENT.title,
              },
              {
                href: createNavItemUrl(
                  strLoginStatus,
                  currentPage,
                  HEADER_NAV_MENU_ITEM.REFER.DROP_DOWN_MENU.SCOUT.href,
                  HEADER_NAV_MENU_ITEM.REFER.DROP_DOWN_MENU.SCOUT.pageParam
                ),
                onClick: function noRefCheck() {},
                text: HEADER_NAV_MENU_ITEM.REFER.DROP_DOWN_MENU.SCOUT.title,
              },
              {
                href: createNavItemUrl(
                  strLoginStatus,
                  currentPage,
                  HEADER_NAV_MENU_ITEM.REFER.DROP_DOWN_MENU.PARTNER.href,
                  HEADER_NAV_MENU_ITEM.REFER.DROP_DOWN_MENU.PARTNER.pageParam
                ),
                onClick: function noRefCheck() {},
                text: HEADER_NAV_MENU_ITEM.REFER.DROP_DOWN_MENU.PARTNER.title,
              },
            ],
            selected: selected === HEADER_NAV_MENU.REFER,
          },
        ]
      : []),
    ...(isLoggedIn
      ? [
          {
            text: HEADER_NAV_MENU_ITEM.OFFER.title,
            href: HEADER_NAV_MENU_ITEM.OFFER.href,
            dropdownMenu: [
              {
                href: createNavItemUrl(
                  strLoginStatus,
                  currentPage,
                  HEADER_NAV_MENU_ITEM.OFFER.DROP_DOWN_MENU.CAREER_LIST.href,
                  HEADER_NAV_MENU_ITEM.OFFER.DROP_DOWN_MENU.CAREER_LIST
                    .pageParam
                ),
                onClick: function noRefCheck() {},
                text: HEADER_NAV_MENU_ITEM.OFFER.DROP_DOWN_MENU.CAREER_LIST
                  .title,
              },
              {
                href: createNavItemUrl(
                  strLoginStatus,
                  currentPage,
                  HEADER_NAV_MENU_ITEM.OFFER.DROP_DOWN_MENU.PROJECT_LIST.href,
                  HEADER_NAV_MENU_ITEM.OFFER.DROP_DOWN_MENU.PROJECT_LIST
                    .pageParam
                ),
                onClick: function noRefCheck() {},
                text: HEADER_NAV_MENU_ITEM.OFFER.DROP_DOWN_MENU.PROJECT_LIST
                  .title,
              },
              {
                href: createNavItemUrl(
                  strLoginStatus,
                  currentPage,
                  HEADER_NAV_MENU_ITEM.OFFER.DROP_DOWN_MENU.INTERVIEW_LIST.href,
                  HEADER_NAV_MENU_ITEM.OFFER.DROP_DOWN_MENU.INTERVIEW_LIST
                    .pageParam
                ),
                onClick: function noRefCheck() {},
                text: HEADER_NAV_MENU_ITEM.OFFER.DROP_DOWN_MENU.INTERVIEW_LIST
                  .title,
              },
              // パートナーエージェントサービス利用中（whtrfl=2）の場合
              ...(partnerAgentServiceFlag
                ? [
                    {
                      href: createNavItemUrl(
                        strLoginStatus,
                        currentPage,
                        HEADER_NAV_MENU_ITEM.OFFER.DROP_DOWN_MENU
                          .PARTNER_SCOUT_LIST.href,
                        HEADER_NAV_MENU_ITEM.OFFER.DROP_DOWN_MENU
                          .PARTNER_SCOUT_LIST.pageParam
                      ),
                      onClick: function noRefCheck() {},
                      text: HEADER_NAV_MENU_ITEM.OFFER.DROP_DOWN_MENU
                        .PARTNER_SCOUT_LIST.title,
                    },
                  ]
                : []),
            ],
            selected: selected === HEADER_NAV_MENU.OFFER,
          },
          {
            text: HEADER_NAV_MENU_ITEM.HISTORY.title,
            href: HEADER_NAV_MENU_ITEM.HISTORY.href,
            dropdownMenu: [
              {
                href: createNavItemUrl(
                  strLoginStatus,
                  currentPage,
                  HEADER_NAV_MENU_ITEM.HISTORY.DROP_DOWN_MENU.TODO_LIST.href,
                  HEADER_NAV_MENU_ITEM.HISTORY.DROP_DOWN_MENU.TODO_LIST
                    .pageParam
                ),
                onClick: function noRefCheck() {},
                text: HEADER_NAV_MENU_ITEM.HISTORY.DROP_DOWN_MENU.TODO_LIST
                  .title,
              },
              {
                href: createNavItemUrl(
                  strLoginStatus,
                  currentPage,
                  HEADER_NAV_MENU_ITEM.HISTORY.DROP_DOWN_MENU.SELECTION_STATUS
                    .href,
                  HEADER_NAV_MENU_ITEM.HISTORY.DROP_DOWN_MENU.SELECTION_STATUS
                    .pageParam
                ),
                onClick: function noRefCheck() {},
                text: HEADER_NAV_MENU_ITEM.HISTORY.DROP_DOWN_MENU
                  .SELECTION_STATUS.title,
              },
            ],
            selected: selected === HEADER_NAV_MENU.HISTORY,
          },
        ]
      : []),
    {
      text: HEADER_NAV_MENU_ITEM.GUIDE.title,
      href: HEADER_NAV_MENU_ITEM.GUIDE.href,
      dropdownMenu: [
        {
          href: createNavItemUrl(
            strLoginStatus,
            currentPage,
            currentPage === HEADER_PAGES.OTHER
              ? HEADER_NAV_MENU_ITEM.GUIDE.DROP_DOWN_MENU.KNOWLEDGE.href
              : HEADER_NAV_MENU_ITEM.GUIDE.href,
            HEADER_NAV_MENU_ITEM.GUIDE.DROP_DOWN_MENU.KNOWLEDGE.pageParam
          ),
          onClick: function noRefCheck() {},
          text: HEADER_NAV_MENU_ITEM.GUIDE.DROP_DOWN_MENU.KNOWLEDGE.title,
        },
        {
          href: createNavItemUrl(
            strLoginStatus,
            currentPage,
            HEADER_NAV_MENU_ITEM.GUIDE.DROP_DOWN_MENU.DATA.href,
            HEADER_NAV_MENU_ITEM.GUIDE.DROP_DOWN_MENU.DATA.pageParam
          ),
          onClick: function noRefCheck() {},
          text: HEADER_NAV_MENU_ITEM.GUIDE.DROP_DOWN_MENU.DATA.title,
        },
        {
          href: createNavItemUrl(
            strLoginStatus,
            currentPage,
            currentPage === HEADER_PAGES.OTHER
              ? HEADER_NAV_MENU_ITEM.GUIDE.DROP_DOWN_MENU.COLUMN_INTERVIEW.href
              : HEADER_NAV_MENU_ITEM.GUIDE.href,
            HEADER_NAV_MENU_ITEM.GUIDE.DROP_DOWN_MENU.COLUMN_INTERVIEW.pageParam
          ),
          onClick: function noRefCheck() {},
          text: HEADER_NAV_MENU_ITEM.GUIDE.DROP_DOWN_MENU.COLUMN_INTERVIEW
            .title,
        },
        {
          href: createNavItemUrl(
            strLoginStatus,
            currentPage,
            HEADER_NAV_MENU_ITEM.GUIDE.DROP_DOWN_MENU.QUESTION_ANSWER.href,
            HEADER_NAV_MENU_ITEM.GUIDE.DROP_DOWN_MENU.QUESTION_ANSWER.pageParam
          ),
          onClick: function noRefCheck() {},
          text: HEADER_NAV_MENU_ITEM.GUIDE.DROP_DOWN_MENU.QUESTION_ANSWER.title,
        },
      ],
      selected: selected === HEADER_NAV_MENU.GUIDE,
    },
    {
      text: HEADER_NAV_MENU_ITEM.EVENT.title,
      href: HEADER_NAV_MENU_ITEM.EVENT.href,
      dropdownMenu: [
        {
          href: createNavItemUrl(
            strLoginStatus,
            currentPage,
            HEADER_NAV_MENU_ITEM.EVENT.DROP_DOWN_MENU.EVENT_SEMINAR.href,
            HEADER_NAV_MENU_ITEM.EVENT.DROP_DOWN_MENU.EVENT_SEMINAR.pageParam
          ),
          onClick: function noRefCheck() {},
          text: HEADER_NAV_MENU_ITEM.EVENT.DROP_DOWN_MENU.EVENT_SEMINAR.title,
        },
        {
          href: createNavItemUrl(
            strLoginStatus,
            currentPage,
            HEADER_NAV_MENU_ITEM.EVENT.DROP_DOWN_MENU.FAIR.href,
            HEADER_NAV_MENU_ITEM.EVENT.DROP_DOWN_MENU.FAIR.pageParam
          ),
          onClick: function noRefCheck() {},
          text: HEADER_NAV_MENU_ITEM.EVENT.DROP_DOWN_MENU.FAIR.title,
        },
        {
          href: createNavItemUrl(
            strLoginStatus,
            currentPage,
            HEADER_NAV_MENU_ITEM.EVENT.DROP_DOWN_MENU.SEMINAR_MOVIE.href,
            HEADER_NAV_MENU_ITEM.EVENT.DROP_DOWN_MENU.SEMINAR_MOVIE.pageParam
          ),
          onClick: function noRefCheck() {},
          text: HEADER_NAV_MENU_ITEM.EVENT.DROP_DOWN_MENU.SEMINAR_MOVIE.title,
        },
      ],
      selected: selected === HEADER_NAV_MENU.EVENT,
    },
  ];
};
