import { NotificationInfoItem as NotificationInfoItemType } from '@doda/common/src/interfaces/api/notificationInfo';

type GetTagClassName = (
  category: NotificationInfoItemType['category'],
  infoType: NotificationInfoItemType['infoType']
) => string;

/**
 * お知らせ種別に対応するclass名を返す
 * @param category お知らせカテゴリ
 * @param infoType オファー種別
 * @returns className タグのクラス名
 */
export const getTagClassName: GetTagClassName = (category, infoType) => {
  switch (category) {
    case NOTIFICATION_CATEGORY.CAREER_ADVISER:
    case NOTIFICATION_CATEGORY.PLUS_SCOUT:
    case NOTIFICATION_CATEGORY.PARTNER_AGENT:
      return 'notificationRow__tag--orange';
    case NOTIFICATION_CATEGORY.OFFER:
      if (infoType === 'premiumInterviewOfferList') {
        return 'notificationRow__tag--pink';
      }
      return 'notificationRow__tag--orange';
    case NOTIFICATION_CATEGORY.SELECTION_STATUS:
      return 'notificationRow__tag--blue';
    case NOTIFICATION_CATEGORY.BOOKMARK_LIST:
    case NOTIFICATION_CATEGORY.WELCOME_MATCH:
      return 'notificationRow__tag--yellow';
    case NOTIFICATION_CATEGORY.OTHER:
      return 'notificationRow__tag--white';
    case NOTIFICATION_CATEGORY.NEW_FEATURE_INFO:
      return 'notificationRow__tag--green';
    default:
      return 'notificationRow__tag--white';
  }
};

// ヘッダー お知らせカテゴリー
const NOTIFICATION_CATEGORY = {
  CAREER_ADVISER: '1',
  PLUS_SCOUT: '2',
  OFFER: '3',
  SELECTION_STATUS: '4',
  BOOKMARK_LIST: '5',
  OTHER: '6',
  WELCOME_MATCH: '7',
  PARTNER_AGENT: '8',
  NEW_FEATURE_INFO: '9',
} as const;
