import React, { FC } from 'react';
import { NotificationInfoItem as NotificationInfoItemType } from '@doda/common/src/interfaces/api/notificationInfo';
import styles from './index.module.scss';

import { NotificationRow } from '../NotificationRow';

/** NotificationList Props*/
interface Props {
  notifications: NotificationInfoItemType[];
}

export const NotificationList: FC<Props> = ({ notifications }) => {
  return (
    <ul className={styles.headerNotificationPopover__notificationList}>
      {notifications.map((notification, index) => (
        <li
          key={index}
          className={styles.headerNotificationPopover__notificationListItem}
        >
          <NotificationRow notification={notification} />
        </li>
      ))}
    </ul>
  );
};
