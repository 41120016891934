import {
  LOGIN_PREFIX,
  LOGOUT_PREFIX,
} from 'pages/DodaFront/View/Top/constants';

/**
 * @param {boolean} isLoggedIn - ログイン状態
 * @returns ログイン状態に応じた prefix
 */
export const getPrefix = (isLoggedIn: boolean) => {
  return isLoggedIn ? LOGIN_PREFIX : LOGOUT_PREFIX;
};
