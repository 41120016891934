import { HeaderPageType } from 'components/Header/interface';
import {
  HEADER_ICON_PARAM,
  HEADER_PAGES,
  HEADER_PAGE_PARAM,
} from 'constants/headerNavMenu';

/**
 * 現在のページに応じてusrclkパラメータを設定する関数
 *
 * @param {HeaderPageType['currentPage']} currentPage - 現在のページ
 * @returns {string | null} - 設定されたusrclkパラメータ、またはnull
 */
export const setUsrclk = (currentPage: HeaderPageType['currentPage']) => {
  if (currentPage === HEADER_PAGES.TOP) {
    return `PC_logout${HEADER_PAGE_PARAM.TOP.menuParam}${HEADER_ICON_PARAM.LOGIN}`;
  }
  if (currentPage === HEADER_PAGES.JOB_SEARCH_LIST) {
    return `PC-logout${HEADER_PAGE_PARAM.JOB_SEARCH_LIST.menuParam}${HEADER_ICON_PARAM.LOGIN}`;
  }
  // TODO: お気に入りリストページ追加時に動作確認
  if (currentPage === HEADER_PAGES.BOOKMARK_LIST) {
    return `PC-logout${HEADER_PAGE_PARAM.BOOKMARK_LIST.menuParam}${HEADER_ICON_PARAM.LOGIN}`;
  }
  return null;
};
