import { SHARE_CONTENTS } from '../../constants';
import styles from './index.module.scss';

export const ShareContent = () => {
  return (
    <ul className={styles.shareContent}>
      {SHARE_CONTENTS.map((listItem) => {
        return (
          <li key={listItem.href}>
            <a href={listItem.href} target="_blank" rel="noopener">
              <img src={listItem.image} alt={listItem.alt} />
            </a>
          </li>
        );
      })}
    </ul>
  );
};
