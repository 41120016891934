import { IncomingHttpHeaders } from 'http';
import { AxiosResponse } from 'axios';
import { appAxios } from '@doda/common/src/libs/axios/appAxios';
import { NotificationInfo } from '@doda/common/src/interfaces/api/notificationInfo';

interface Options {
  headers?: IncomingHttpHeaders;
  params: {
    lastTimeImmediate: string;
    lastTimeRegMain: string;
    lastTimeRegOther: string;
    resumeUploadDispTime: string;
    wsfid: string;
  };
}

/**
 * お知らせ情報取得
 * @param {IncomingHttpHeaders} headers?
 * @param {
 *          lastTimeImmediate: string; - 前回取得時刻(即時取得)
 *          lastTimeRegMain: string; - 前回取得時刻(12,20時取得)
 *          lastTimeRegOther: string; - 前回取得時刻(8時取得)
 *          resumeUploadDispTime: string; - 職歴書UL促進最終表示時刻
 *          wsfid: string; - 候補者ID
 *        } params
 */
export const getNotificationInfo = async ({
  headers,
  params,
}: Options): Promise<NotificationInfo> => {
  return appAxios({
    method: 'get',
    url: '/dcfront/cms/cmsMemberInfo/getNotificationInfo/',
    params,
    headers,
  })
    .then((response: AxiosResponse<NotificationInfo>) => {
      return response.data;
    })
    .catch();
};
