import { HEADER_PAGES, HEADER_PAGE_PARAM } from 'constants/headerNavMenu';
import { HeaderPageType } from '../../interface';

/**
 *  TOPページ・検索結果一覧ページ・お気に入りリストページからの遷移時に、パラメータを付与する
 * @param {string} strLoginStatus ログインステータス
 * @param {string} currentPage 現在のページ
 * @returns パラメータ付きURL
 */
export const createLogoUrl = (
  strLoginStatus: string,
  currentPage: HeaderPageType['currentPage']
) => {
  if (currentPage === HEADER_PAGES.TOP) {
    return `/?${HEADER_PAGE_PARAM.TOP.USRCLK}=PC_${strLoginStatus}${HEADER_PAGE_PARAM.TOP.logoParam}`;
  }

  if (currentPage === HEADER_PAGES.JOB_SEARCH_LIST) {
    return `/?${HEADER_PAGE_PARAM.JOB_SEARCH_LIST.USRCLK}=PC-${strLoginStatus}${HEADER_PAGE_PARAM.JOB_SEARCH_LIST.logoParam}`;
  }

  // TODO: お気に入りリストページ追加時にパラメータが新基盤と同じか確認
  if (currentPage === HEADER_PAGES.BOOKMARK_LIST) {
    return `/?${HEADER_PAGE_PARAM.BOOKMARK_LIST.USRCLK}=PC-${strLoginStatus}${HEADER_PAGE_PARAM.BOOKMARK_LIST.logoParam}`;
  }

  return '/';
};
