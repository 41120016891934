// ヘッダー アクセス解析 計測用props
export const MEASURE_ONCLICK_HEADER = {
  // 閲覧履歴
  viewHistory: {
    linkName: 'PC_header_etsuranrireki_cl',
    linkTrackVars: 'prop23,eVar23',
    prop23: 'PC_header',
    eVar23: 'D=c23',
  },
  // 検討リスト
  consideration: {
    linkName: 'PC_header_kentolist_cl',
    linkTrackVars: 'prop23,eVar23',
    prop23: 'PC_header',
    eVar23: 'D=c23',
  },
  // Web履歴書
  webResume: {
    linkName: 'PC_header_webrirekisho_cl',
    linkTrackVars: 'prop23,eVar23',
    prop23: 'PC_header',
    eVar23: 'D=c23',
  },
  // 登録
  register: {
    linkName: 'PC_header_toroku_cl',
    linkTrackVars: 'prop23,eVar23',
    prop23: 'PC_header',
    eVar23: 'D=c23',
  },
  // ログイン
  login: {
    linkName: 'PC_header_login_cl',
    linkTrackVars: 'prop23,eVar23',
    prop23: 'PC_header',
    eVar23: 'D=c23',
  },
  // ログアウト
  logout: {
    linkName: 'PC_header_logout_cl',
    linkTrackVars: 'prop23,eVar23',
    prop23: 'PC_header',
    eVar23: 'D=c23',
  },
  // お知らせ 開く
  newsOpen: {
    linkName: 'PC_header_oshirase_cl',
    linkTrackVars: 'prop23,eVar23',
    prop23: 'PC_header',
    eVar23: 'D=c23',
  },
  // お知らせ 閉じる
  newsClose: {
    linkName: 'PC_header_oshirase_close_cl',
    linkTrackVars: 'prop23,eVar23',
    prop23: 'PC_header',
    eVar23: 'D=c23',
  },
  // 登録情報設定
  registerInfoSetting: {
    linkName: 'PC_header_torokujohosettei_cl',
    linkTrackVars: 'prop23,eVar23',
    prop23: 'PC_header',
    eVar23: 'D=c23',
  },
} as const;
