import { Button } from '@doda/io-react/common';
import {
  GROUP_COMPANIES,
  PERSONAL_SERVICES,
  CORPORATE_SERVICES,
} from '../../constants';
import styles from './index.module.scss';

/**
 * テキストリンク
 */
interface Link {
  // テキストリンクのURL
  href: string;
  // テキスト
  title: string;
}

/**
 * タイトルと関連リンクを持つサービス
 */
interface Service {
  // サービスのタイトル
  title: string;
  // サービスに関連するリンクの配列
  links: Link[];
}

/**
 * ServiceSectionコンポーネントのプロパティ
 */
interface ServiceSectionProps {
  // サービスセクションのタイトル
  title: string;
  // セクションに表示するサービスの配列
  services: Service[];
  // ボタンにセットするURL
  buttonHref: string;
  // ボタンに表示されるテキスト
  buttonText: string;
}

const ServiceSection: React.FC<ServiceSectionProps> = ({
  title,
  services,
  buttonHref,
  buttonText,
}) => (
  <div className={styles.serviceLinks_wrapper}>
    <h3 className={styles.serviceLinks_title}>{title}</h3>
    <div className={styles.serviceLinks_list}>
      {services.map((item) => (
        <div className={styles.serviceLinks_serviceList} key={item.title}>
          <h4 className={styles.serviceLinks_serviceListTitle}>{item.title}</h4>
          <ul className={styles.serviceLinks_listWrapper}>
            {item.links.map((link) => (
              <li key={link.href} className={styles.serviceLinks_listItem}>
                <a href={link.href} target="_blank" rel="noopener">
                  {link.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
    <Button
      as="a"
      size="XS"
      variant="outline_gray"
      href={buttonHref}
      widthPercentage={100}
      className={styles.serviceLinks_button}
      target="_blank"
      rel="noopener"
    >
      {buttonText}
    </Button>
  </div>
);

export const ServiceLinks = () => {
  return (
    <div className={styles.serviceLinks}>
      <div className={styles.serviceLinks_wrapper}>
        <h3 className={styles.serviceLinks_title}>グループ会社</h3>
        <div className={styles.serviceLinks_list}>
          <ul className={styles.serviceLinks_listWrapper}>
            {GROUP_COMPANIES.map((item) => (
              <li key={item.href} className={styles.serviceLinks_listItem}>
                <a href={item.href} target="_blank" rel="noopener">
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <Button
          as="a"
          size="XS"
          variant="outline_gray"
          href="https://www.persol-group.co.jp/corporate/group/"
          widthPercentage={100}
          className={styles.serviceLinks_button}
          target="_blank"
          rel="noopener"
        >
          グループ会社一覧
        </Button>
      </div>
      <ServiceSection
        title="個人向けサービス"
        services={PERSONAL_SERVICES}
        buttonHref="https://www.persol-group.co.jp/service/personal/"
        buttonText="サービス一覧"
      />
      <ServiceSection
        title="法人向けサービス"
        services={CORPORATE_SERVICES}
        buttonHref="https://www.persol-group.co.jp/service/business/"
        buttonText="サービス一覧"
      />
    </div>
  );
};
