import React, { CSSProperties, FC, ReactNode, useEffect, useRef } from 'react';
import Image from 'next/image';
import styles from './index.module.scss';

/** Popover Props*/
export interface Props {
  children: ReactNode;
  onClose: () => void;
  isNotificationPopover: boolean;
  positionLeft?: CSSProperties['left'];
  arrowPositionLeft?: CSSProperties['left'];
  height?: CSSProperties['height'];
}

/**
 * 吹き出し
 */
export const Popover: FC<Props> = ({
  children,
  onClose,
  isNotificationPopover,
  positionLeft = '10px',
  arrowPositionLeft = '15px',
  height = 'auto',
}) => {
  // お知らせ吹き出し以外を押下した時の処理
  const insideRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // お知らせ吹き出しを取得
    const popoverWrapper = insideRef.current;
    if (!popoverWrapper) return undefined;

    // クリックした時に実行する関数
    const onClickOutsideNotificationPopover = (e: MouseEvent) => {
      if (popoverWrapper && !popoverWrapper?.contains(e.target as Node)) {
        // 吹き出し以外をクリックした時は吹き出しを閉じる
        onClose();
      }
    };

    // クリックイベントを設定
    document.addEventListener('click', onClickOutsideNotificationPopover);

    // クリーンアップ関数
    return () => {
      // コンポーネントがアンマウント、再レンダリングされたときにクリックイベントを削除
      document.removeEventListener('click', onClickOutsideNotificationPopover);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insideRef]);

  const wrapperStyle = {
    '--height': height,
    '--position-left': positionLeft,
    '--arrow-position-left': arrowPositionLeft,
    height,
  } as CSSProperties;
  return (
    <div
      className={styles.popover__wrapper}
      style={wrapperStyle}
      ref={isNotificationPopover ? insideRef : null}
    >
      <button
        onClick={onClose}
        type="button"
        className={styles.popover__closeButton}
      >
        <Image
          src="/assets/icon/close_black.svg"
          alt="バツ"
          width="24"
          height="24"
          unoptimized
        />
      </button>
      <div className={styles.popover__childrenWrapper}>{children}</div>
    </div>
  );
};
