import {
  DODA_SITE_URL,
  RECOMMEND_LIST_URL,
} from '@doda/common/src/constants/urls';
import { HEADER_ICON_PARAM, HEADER_PAGES } from 'constants/headerNavMenu';
import { createIconMenuUrl } from '../createIconMenuUrl';
import { HeaderPageType } from '../../interface';

/**
 *  ヘッダーのメニューアイコンのURLを返す
 * @param {string} currentPage 現在のページ
 * @param {string} isLoggedOut ログインステータス
 * @returns ヘッダーのメニューアイコンのURL
 */
export const getIconMeneUrl = (
  currentPage: HeaderPageType['currentPage'],
  isLoggedOut: boolean
) => {
  const strLoginStatus = isLoggedOut ? 'logout' : 'login';

  const jobHistoryUrl = createIconMenuUrl(
    strLoginStatus,
    currentPage,
    DODA_SITE_URL.jobHistoryList,
    HEADER_ICON_PARAM.JOB_HISTORY_LIST
  );

  const bookmarkListUrl = createIconMenuUrl(
    strLoginStatus,
    currentPage,
    RECOMMEND_LIST_URL.bookmark_list,
    HEADER_ICON_PARAM.BOOKMARK_LIST
  );

  const webResumeUrl = createIconMenuUrl(
    strLoginStatus,
    currentPage,
    isLoggedOut ? DODA_SITE_URL.logoutWebResume : DODA_SITE_URL.webResume,
    currentPage === HEADER_PAGES.TOP
      ? HEADER_ICON_PARAM.WEB_RESUME.TOP
      : HEADER_ICON_PARAM.WEB_RESUME.JOB_HISTORY_LIST_AND_BOOKMARK_LIST,
    false,
    !!isLoggedOut
  );

  const settingUrl = createIconMenuUrl(
    strLoginStatus,
    currentPage,
    DODA_SITE_URL.settings,
    currentPage === HEADER_PAGES.TOP
      ? HEADER_ICON_PARAM.SETTING.TOP
      : HEADER_ICON_PARAM.SETTING.JOB_HISTORY_LIST_AND_BOOKMARK_LIST
  );

  const logoutUrl = createIconMenuUrl(
    strLoginStatus,
    currentPage,
    `${DODA_SITE_URL.logout}?url=${encodeURIComponent('/')}`,
    HEADER_ICON_PARAM.LOGOUT,
    true
  );

  const registrationUrl = createIconMenuUrl(
    strLoginStatus,
    currentPage,
    DODA_SITE_URL.registration,
    HEADER_ICON_PARAM.REGISTER,
    true
  );

  return {
    jobHistoryUrl,
    bookmarkListUrl,
    webResumeUrl,
    settingUrl,
    logoutUrl,
    registrationUrl,
  };
};
