import { Footer } from 'components/Footer';
import { Footer as TopFooter } from '@doda/pc/src/pages/DodaFront/View/Top/components/Footer';
import { ReactNode } from 'react';
import { ScrollToTopButton } from '@doda/io-react/pc';
import { Header } from 'components/Header';
import { useHeader } from 'components/Header/hooks/useHeader';
import { useSelector } from 'react-redux';
import { adobeAnalyticsScriptsSelector } from '@doda/common/src/redux/features/analytics';
import {
  HEADER_PAGES,
  HeaderNavMenu,
} from '@doda/pc/src/constants/headerNavMenu';
import { HeaderPageType } from 'components/Header/interface';
import { loginStatusSelector } from '@doda/common/src/redux/features/memberInfo';
import { MoveToSpSite } from '@doda/pc/src/pages/DodaFront/View/Top/components/MoveToSpSite';
import styles from './index.module.scss';

/**
 * BasicLayout のprops
 */
interface Props {
  children: ReactNode;
  selectedNavItem?: HeaderNavMenu;
  shouldShowResumePopup?: boolean;
  leadSentence?: string;
  currentPage?: HeaderPageType['currentPage'];
  isViewModePC?: boolean;
}

export const BasicLayout = ({
  children,
  selectedNavItem,
  shouldShowResumePopup = false,
  leadSentence,
  currentPage,
  isViewModePC,
}: Props) => {
  const {
    notifications,
    notificationBadgeCount,
    memberInfo,
    partnerAgentServiceFlag,
  } = useHeader();
  const hasAdobeAnalyticsScripts = useSelector(adobeAnalyticsScriptsSelector);
  const isLoggedIn = useSelector(loginStatusSelector);
  const shouldShowMoveToSpSite =
    isViewModePC && currentPage === HEADER_PAGES.TOP;

  return (
    <>
      {shouldShowMoveToSpSite && <MoveToSpSite />}
      <div className={styles.basicLayout__header}>
        <div className={styles.basicLayout__header__inner}>
          <Header
            bookmarkCount={memberInfo?.bookmarkCnt ?? 0}
            browsingHistoryCount={memberInfo?.jobHistoryCnt ?? 0}
            isLoggedIn={memberInfo?.loginStatus ?? false}
            userName={`${memberInfo?.lastName} ${memberInfo?.firstName}`}
            partnerAgentServiceFlag={partnerAgentServiceFlag}
            notifications={notifications}
            badgeCount={notificationBadgeCount}
            selectedNavItem={selectedNavItem}
            shouldShowResumePopup={shouldShowResumePopup}
            leadSentence={leadSentence}
            currentPage={currentPage}
            hasAdobeAnalyticsScripts={hasAdobeAnalyticsScripts}
          />
        </div>
      </div>
      <div className={styles.basicLayout__wrapper}>
        <main>{children}</main>
        {currentPage === HEADER_PAGES.TOP ? (
          <TopFooter isLoggedIn={isLoggedIn} />
        ) : (
          <Footer />
        )}
        <ScrollToTopButton />
      </div>
    </>
  );
};
