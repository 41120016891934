import { isServer } from '@doda/common/src/utils/isServer';
import { createUrl } from '@doda/common/src/utils/urlUtil';
import { MouseEvent } from 'react';
import { useAppDispatch } from '@doda/common/src/redux/hooks';
import { HEADER_PAGES } from 'constants/headerNavMenu';
import { DODA_SITE_URL } from '@doda/common/src/constants/urls';
import { setUsrclk } from 'components/Header/logics/setUsrclk';
import { MEASURE_ONCLICK_HEADER } from '../../../../constants';
import { measureOnClickHeader } from '../../../../logics/analytics';

/**
 * useLoginカスタムフック
 *
 * @param {string} currentPage - 現在のページ
 * @param {boolean} hasAdobeAnalyticsScripts - Adobe Analyticsのスクリプトが存在するか
 * @returns {Object} - フックによって提供される関数
 */
export const useLogin = (
  currentPage: string,
  hasAdobeAnalyticsScripts: boolean
) => {
  const dispatch = useAppDispatch();

  /**
   * ログインURLを取得する関数
   * 同時に、ログイン後元の画面に戻るためのクエリパラメータを付与する。
   *
   * @returns {string} - ログインURL
   */
  const getLoginURL = (): string => {
    if (isServer) return '';

    const queryParams = {
      ...(currentPage === HEADER_PAGES.TOP ||
      currentPage === HEADER_PAGES.JOB_SEARCH_LIST ||
      currentPage === HEADER_PAGES.BOOKMARK_LIST
        ? { usrclk: setUsrclk(currentPage) }
        : {}),
      url: encodeURIComponent(window.location.href), // ログイン後に戻ってくるurl
    };

    return createUrl({
      params: queryParams,
      href: DODA_SITE_URL.loginScreen,
    });
  };

  /**
   * ログイン処理を行う関数
   *
   * @param {MouseEvent<HTMLAnchorElement>} event - マウスイベント
   */
  const handleLogin = (event: MouseEvent<HTMLAnchorElement>): void => {
    measureOnClickHeader({
      hasAdobeAnalyticsScripts,
      dispatch,
      measureTarget: MEASURE_ONCLICK_HEADER.login,
    });

    const loginURL = getLoginURL();
    event.currentTarget.setAttribute('href', loginURL);
  };

  return { handleLogin };
};
