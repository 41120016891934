import React, { FC } from 'react';
import { Button, FillTag, Text } from '@doda/io-react/common';
import styles from './index.module.scss';
import { Popover } from '../Popover';

/** HeaderResumePopoverコンポーネントのプロパティ */
interface Props {
  /** ポップオーバーを閉じるためのコールバック関数 */
  onClose: () => void;
}

/**
 * HeaderResumePopoverコンポーネント
 *
 * @param {Props} props - コンポーネントのプロパティ
 * @param {() => void} props.onClose - ポップオーバーを閉じるためのコールバック関数
 * @returns {JSX.Element} - HeaderResumePopoverコンポーネント
 */
export const HeaderResumePopover: FC<Props> = ({ onClose }) => {
  return (
    <Popover
      onClose={onClose}
      isNotificationPopover={false}
      positionLeft="-150px"
      arrowPositionLeft="178px"
      height="197px"
    >
      <div className={styles.headerResumePopover__wrapper}>
        <FillTag color="red" className={styles.headerResumePopover_tag}>
          NEW
        </FillTag>
        <div className={styles.headerResumePopover_textWrapper}>
          <Text size="14--articlePC">
            登録情報から職務経歴書（Word形式）の自動生成が完了しました。
            ダウンロードして再編集できます。
          </Text>
        </div>
        <Button
          as="a"
          href="/dcfront/mypage/mypageResumeUpload/?rsf=7"
          variant="green"
          size="L"
          style={{
            width: '300px',
            height: '48px',
            textDecoration: 'none',
            fontSize: 16,
          }}
        >
          自分の職務経歴書をみる
        </Button>
      </div>
    </Popover>
  );
};
