import {
  FooterNavigation,
  Footer as FooterPrivacyArea,
  HorizontalLinkList,
} from '@doda/io-react/pc';
import { FC } from 'react';
import styles from './index.module.scss';
import { ShareContent } from './components/ShareContent';
import { RelationInfoContent } from './components/RelationInfoContent';
import { getPrefix } from '../../logics/getPrefix';
import {
  getExternalSites,
  getRules,
  getPersonalInformation,
} from './constants';
import { ServiceLinks } from './components/ServiceLinks';

/**
 * Footerコンポーネント
 */
interface Props {
  // isLoggedIn ログイン状態
  isLoggedIn: boolean;
}

export const Footer: FC<Props> = ({ isLoggedIn }) => {
  const prefix = getPrefix(isLoggedIn);
  const rules = getRules(prefix);
  const externalSites = getExternalSites(prefix);

  return (
    <section className={styles.footer}>
      <FooterNavigation
        shareContent={<ShareContent />}
        upperRightContent={<HorizontalLinkList linkList={rules} />}
        relationInfoContent={<RelationInfoContent prefix={prefix} />}
      />
      <div className={styles.footer__externalSites}>
        <HorizontalLinkList fontColor="gray" linkList={externalSites} />
      </div>
      <ServiceLinks />
      <FooterPrivacyArea
        className={styles.footer__PrivacyArea}
        href={getPersonalInformation(prefix)}
      />
    </section>
  );
};
