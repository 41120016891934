import { NotificationInfoItem as NotificationInfoItemType } from '@doda/common/src/interfaces/api/notificationInfo';

type GetTagText = (category: NotificationInfoItemType['category']) => string;

/**
 * お知らせカテゴリに対応するタグのテキストを返す
 * @param category お知らせカテゴリ
 * @returns テキスト
 */
export const getTagText: GetTagText = (category) => {
  if (category === null) return DEFAULT_TAG_TEXT;

  return NOTIFICATION_CATEGORY_TEXT_MAP[category] || DEFAULT_TAG_TEXT;
};

/**
 * お知らせカテゴリーとタグのテキストを対応させるオブジェクト
 */
export const NOTIFICATION_CATEGORY_TEXT_MAP = {
  '1': 'キャリアアドバイザー',
  '2': '採用プロジェクト担当',
  '3': '企業担当者',
  '4': '選考状況',
  '5': '気になるリスト',
  '6': 'その他',
  '7': '応募歓迎',
  '8': 'パートナーエージェント',
  '9': '新機能リリース',
} as const;

/**
 * 当てはまるカテゴリがない場合のタグテキスト
 */
const DEFAULT_TAG_TEXT = 'その他';
