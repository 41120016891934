import { useState } from 'react';

type VisiblePopover = 'notification' | 'resume' | null;

/**
 * 吹き出しの表示/非表示を制御するカスタムフック
 *
 * @param {boolean} shouldShowResumePopup - レジュメポップアップの表示判定
 * @returns {{
 *   isVisibleNotificationPopover: boolean;
 *   isVisibleResumePopover: boolean;
 *   showNotificationPopover: () => void;
 *   hidePopover: () => void;
 * }}
 */
export const usePopoverVisible = (shouldShowResumePopup: boolean) => {
  const initialState = shouldShowResumePopup ? 'resume' : null;
  const [visiblePopover, setVisiblePopover] =
    useState<VisiblePopover>(initialState);

  const isVisibleNotificationPopover = visiblePopover === 'notification';
  const isVisibleResumePopover = visiblePopover === 'resume';

  /**
   * 通知Popoverを表示する関数
   */
  const showNotificationPopover = () => {
    setVisiblePopover('notification');
  };

  /**
   * Popoverを非表示にする関数
   */
  const hidePopover = () => {
    setVisiblePopover(null);
  };
  return {
    isVisibleNotificationPopover,
    isVisibleResumePopover,
    showNotificationPopover,
    hidePopover,
  };
};
