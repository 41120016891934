export const LOGIN_PREFIX = 'PC_login_';
export const LOGOUT_PREFIX = 'PC_logout_';

export const OCCUPATIONS = [
  { name: '職種', value: '' },
  { name: '営業職', value: '01L' },
  { name: '企画・管理', value: '02L' },
  { name: '事務・アシスタント', value: '11L' },
  { name: '販売・サービス職', value: '09L' },
  {
    name: '専門職（コンサルティングファーム・専門事務所・監査法人）',
    value: '07L',
  },
  { name: '金融系専門職', value: '13L' },
  { name: '公務員・教員・農林水産関連職', value: '10L' },
  { name: '技術職（SE・インフラエンジニア・Webエンジニア）', value: '03L' },
  { name: '技術職（機械・電気）', value: '04L' },
  { name: '技術職（組み込みソフトウェア）', value: '14L' },
  {
    name: '技術職・専門職（建設・建築・不動産・プラント・工場）',
    value: '06L',
  },
  { name: '技術職（化学・素材・化粧品・トイレタリー）', value: '05L' },
  { name: '技術職（食品・香料・飼料）', value: '15L' },
  { name: '医療系専門職', value: '12L' },
  { name: 'クリエイター・クリエイティブ職', value: '08L' },
] as const;

export const LOCATIONS = [
  { name: '勤務地', value: '' },
  { name: '北海道', value: '1' },
  { name: '青森県', value: '2' },
  { name: '岩手県', value: '3' },
  { name: '宮城県', value: '4' },
  { name: '秋田県', value: '5' },
  { name: '山形県', value: '6' },
  { name: '福島県', value: '7' },
  { name: '茨城県', value: '8' },
  { name: '栃木県', value: '9' },
  { name: '群馬県', value: '10' },
  { name: '埼玉県', value: '11' },
  { name: '千葉県', value: '12' },
  { name: '東京都', value: '13' },
  { name: '神奈川県', value: '14' },
  { name: '新潟県', value: '15' },
  { name: '富山県', value: '16' },
  { name: '石川県', value: '17' },
  { name: '福井県', value: '18' },
  { name: '山梨県', value: '19' },
  { name: '長野県', value: '20' },
  { name: '岐阜県', value: '21' },
  { name: '静岡県', value: '22' },
  { name: '愛知県', value: '23' },
  { name: '三重県', value: '24' },
  { name: '滋賀県', value: '25' },
  { name: '京都府', value: '26' },
  { name: '大阪府', value: '27' },
  { name: '兵庫県', value: '28' },
  { name: '奈良県', value: '29' },
  { name: '和歌山県', value: '30' },
  { name: '鳥取県', value: '31' },
  { name: '島根県', value: '32' },
  { name: '岡山県', value: '33' },
  { name: '広島県', value: '34' },
  { name: '山口県', value: '35' },
  { name: '徳島県', value: '36' },
  { name: '香川県', value: '37' },
  { name: '愛媛県', value: '38' },
  { name: '高知県', value: '39' },
  { name: '福岡県', value: '40' },
  { name: '佐賀県', value: '41' },
  { name: '長崎県', value: '42' },
  { name: '熊本県', value: '43' },
  { name: '大分県', value: '44' },
  { name: '宮崎県', value: '45' },
  { name: '鹿児島県', value: '46' },
  { name: '沖縄県', value: '47' },
  { name: '海外', value: '99' },
] as const;

export const INCOMES = [
  { name: '年収', value: '' },
  { name: '200万円～', value: '20,0' },
  { name: '250万円～', value: '25,0' },
  { name: '300万円～', value: '30,0' },
  { name: '350万円～', value: '35,0' },
  { name: '400万円～', value: '40,0' },
  { name: '450万円～', value: '45,0' },
  { name: '500万円～', value: '50,0' },
  { name: '550万円～', value: '55,0' },
  { name: '600万円～', value: '60,0' },
  { name: '650万円～', value: '65,0' },
  { name: '700万円～', value: '70,0' },
  { name: '800万円～', value: '80,0' },
  { name: '900万円～', value: '90,0' },
  { name: '1,000万円～', value: '100,0' },
] as const;

// 計測パラメータ
export const MEASUREMENT_PARAMETERS_LOGOUT = {
  pr: 'loc',
  oc: 'job',
  ha: 'inc',
  k: 'kwd',
} as const;

// 計測パラメータ
export const MEASUREMENT_PARAMETERS_LOGIN = {
  k: 'kwd',
  oc: 'job',
  pr: 'location',
  ha: 'income',
} as const;

export const QUICK_SEARCH_MAP = {
  occupation: OCCUPATIONS,
  location: LOCATIONS,
  income: INCOMES,
} as const;

export const QUERY_KEY_NAME = {
  occupation: 'oc',
  location: 'pr',
  income: 'ha',
  keyword: 'k',
  isNewArrival: 'op',
  preBtn: 'preBtn',
} as const;

// クイック検索のパラメータ優先順位(キーワードを含まない場合)
export const CANONICAL_KEYS = ['pr', 'oc', 'op', 'ha', 'preBtn'] as const;

export const QUICK_SEARCH_STATIC_PARAM = 'sid=TopSearch';

export const QUICK_SEARCH_USRCLK = 'kyujinSearchArea_searchButton';

export const PASSWORD_CHANGE_NOTIFICATION_REFERENCE_DATE =
  '2023-03-14T16:00:00.000Z';
