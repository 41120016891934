import { HeaderPageType } from 'components/Header/interface';
import { HEADER_PAGES, HEADER_PAGE_PARAM } from 'constants/headerNavMenu';

/**
 *  TOPページ・検索結果一覧ページ・お気に入りリストページからの遷移時に、パラメータを付与する
 * @param {string} strLoginStatus ログインステータス
 * @param {string} currentPage 現在のページ
 * @param {string} baseUrl ベースURL
 * @param {string} pageParam 画面パラメータ
 * @param {boolean} isRegistration 会員登録フラグ
 * @param {boolean} isEncode エンコードフラグ
 * @returns baseUrl
 */
export const createIconMenuUrl = (
  strLoginStatus: string,
  currentPage: HeaderPageType['currentPage'],
  baseUrl: string,
  pageParam: string,
  isRegistration?: boolean,
  isEncode?: boolean
) => {
  const paramSymbol = isRegistration ? '&' : '?';

  if (currentPage === HEADER_PAGES.OTHER || !pageParam) return baseUrl;

  if (currentPage === HEADER_PAGES.TOP) {
    if (isEncode) {
      return `${baseUrl}${encodeURIComponent(
        `${paramSymbol}${HEADER_PAGE_PARAM.TOP.USRCLK}=PC_${strLoginStatus}${HEADER_PAGE_PARAM.TOP.menuParam}${pageParam}`
      )}`;
    }
    return `${baseUrl}${paramSymbol}${HEADER_PAGE_PARAM.TOP.USRCLK}=PC_${strLoginStatus}${HEADER_PAGE_PARAM.TOP.menuParam}${pageParam}`;
  }
  if (currentPage === HEADER_PAGES.JOB_SEARCH_LIST) {
    if (isEncode) {
      return `${baseUrl}${encodeURIComponent(
        `${paramSymbol}${HEADER_PAGE_PARAM.JOB_SEARCH_LIST.USRCLK}=PC-${strLoginStatus}${HEADER_PAGE_PARAM.JOB_SEARCH_LIST.menuParam}${pageParam}`
      )}`;
    }
    return `${baseUrl}${paramSymbol}${HEADER_PAGE_PARAM.JOB_SEARCH_LIST.USRCLK}=PC-${strLoginStatus}${HEADER_PAGE_PARAM.JOB_SEARCH_LIST.menuParam}${pageParam}`;
  }
  // TODO: お気に入りリストページ追加時にパラメータが新基盤と同じか確認
  if (currentPage === HEADER_PAGES.BOOKMARK_LIST) {
    if (isEncode) {
      return `${baseUrl}${encodeURIComponent(
        `${paramSymbol}${HEADER_PAGE_PARAM.BOOKMARK_LIST.USRCLK}=PC-${strLoginStatus}${HEADER_PAGE_PARAM.BOOKMARK_LIST.menuParam}${pageParam}`
      )}`;
    }
    return `${baseUrl}${paramSymbol}${HEADER_PAGE_PARAM.BOOKMARK_LIST.USRCLK}=PC-${strLoginStatus}${HEADER_PAGE_PARAM.BOOKMARK_LIST.menuParam}${pageParam}`;
  }

  return baseUrl;
};
