import { deleteCookie, setCookie } from '@doda/common/src/utils/cookieUtil';
import { COOKIE } from '@doda/common/src/constants/cookie';
import styles from './index.module.scss';

export const MoveToSpSite = () => {
  const onClick = () => {
    deleteCookie(COOKIE.VIEW_MODE);

    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 60);

    setCookie(COOKIE.VIEW_MODE, 'sp', {
      expires: expirationDate.toUTCString(),
      domain: '.doda.jp',
    });

    window.location.reload();
  };

  return (
    <div className={styles.moveToSpSite}>
      <button type="button" onClick={onClick}>
        <img
          src="//src.doda.jp/cmn_web/img/index/bnr_smtphone02.png?v=20240716a"
          width="1024"
          alt="「スマートフォンサイトを見る」バナー"
        />
      </button>
    </div>
  );
};
