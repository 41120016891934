import { tlReadyExecute } from '@doda/common/src/utils/adobeAnalyticsUtil';
import { Dispatch } from '@reduxjs/toolkit';

/**
 * ヘッダークリック計測用の引数を定義するインターフェース
 */
interface ArgumentType {
  /** AAスクリプト読み込みステータス */
  hasAdobeAnalyticsScripts: boolean;
  /** ReduxのDispatch関数 */
  dispatch: Dispatch;
  /** 計測対象となる情報 */
  measureTarget: {
    /** リンク名 */
    linkName: string;
    /** トラック変数の文字列 */
    linkTrackVars: string;
    /** プロパティ23 */
    prop23: string;
    /** eVar23 */
    eVar23: string;
  };
  /** 設定するクッキー（オプション） */
  setCookie?: string;
}

/**
 * ヘッダークリックを計測する関数
 *
 * @param {ArgumentType} params - ヘッダークリック計測用の引数
 * @param {boolean} params.hasAdobeAnalyticsScripts - AAスクリプト読み込みステータス
 * @param {Dispatch} params.dispatch - ReduxのDispatch関数
 * @param {Object} params.measureTarget - 計測対象となる情報
 * @param {string} params.measureTarget.linkName - リンク名
 * @param {string} params.measureTarget.linkTrackVars - トラック変数の文字列
 * @param {string} params.measureTarget.prop23 - プロパティ23
 * @param {string} params.measureTarget.eVar23 - eVar23
 * @param {string} [params.setCookie] - 設定するクッキー（オプション）
 */
export const measureOnClickHeader = ({
  hasAdobeAnalyticsScripts,
  dispatch,
  measureTarget,
  setCookie,
}: ArgumentType) => {
  if (setCookie) {
    window._satellite?.cookie.set('PvClick', setCookie);
  }
  tlReadyExecute(hasAdobeAnalyticsScripts, dispatch, measureTarget.linkName, {
    linkTrackVars: measureTarget.linkTrackVars,
    prop23: measureTarget.prop23,
    eVar23: measureTarget.eVar23,
  });
};
