import { FC } from 'react';
import { NotificationInfoItem as NotificationInfoItemType } from '@doda/common/src/interfaces/api/notificationInfo';
import classNames from 'classnames';
import { Text } from '@doda/io-react/common';
import styles from './index.module.scss';
import { getTagClassName } from './logics/getTagClassName';
import { getTagText } from './logics/getTagText';

/** NotificationRow Props*/
interface Props {
  notification: NotificationInfoItemType;
}

/**
 * お知らせ一覧で使う、お知らせコンポーネント
 */
export const NotificationRow: FC<Props> = ({ notification }) => {
  const {
    category,
    infoType,
    readedFlg: isRead,
    url,
    text,
    entryDate,
  } = notification;
  const tagClassName = getTagClassName(category, infoType);
  const tagText = getTagText(category);
  return (
    // TODO: sp同様ユーザークリックの計測パラメータをクエリパラメータに追加する必要があるか調査して、必要なら追加
    <a
      href={url}
      className={classNames(styles.notificationRow__link, {
        [styles['notificationRow__link--read']]: isRead,
      })}
    >
      <div className={styles.notificationRow__metaRow}>
        <div className={classNames(styles.notificationRow__tag, tagClassName)}>
          {tagText}
        </div>
        <div className={styles.notificationRow__entryDate}>{entryDate}</div>
      </div>
      <Text color="blue" className={styles.notificationRow__content}>
        {text}
      </Text>
    </a>
  );
};
