import { NavigationLinkList } from '@doda/io-react/pc';
import { UsrclkPrefix } from 'pages/DodaFront/View/Top/types';
import {
  getAreas,
  getEvents,
  getRelatedInformations,
  getServices,
  getSpecialties,
} from '../../constants';
import styles from './index.module.scss';

/**
 * 関連情報ナビゲーションのProps
 */
interface Props {
  // prefix 接頭辞（ログイン状態）
  prefix: UsrclkPrefix;
}

export const RelationInfoContent: React.FC<Props> = ({ prefix }) => {
  const navigationLinks = [
    {
      className: styles.navigation_service,
      title: 'doda転職サービス',
      linkList: getServices(prefix),
    },
    {
      className: styles.navigation_specialty,
      title: '専門サイト',
      linkList: getSpecialties(prefix),
    },
    {
      className: styles.navigation_area,
      title: '地域別サイト',
      linkList: getAreas(prefix),
    },
    {
      className: styles.navigation_event,
      title: '転職イベント・セミナー',
      linkList: getEvents(prefix),
    },
    {
      className: styles.navigation_relatedInformation,
      title: '関連情報',
      linkList: getRelatedInformations(prefix),
      horizontalLink: true,
    },
  ];

  return (
    <>
      {navigationLinks.map(({ className, title, linkList, horizontalLink }) => (
        <NavigationLinkList
          key={className}
          className={className}
          title={title}
          linkList={linkList}
          horizontalLink={horizontalLink}
        />
      ))}
    </>
  );
};
